@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.header {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // height: 72px;
  // width: 100%;
  // z-index: 40;
  // position: fixed;
  // box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  // backdrop-filter: blur(10px);
  // padding: 0 40px;
  // box-sizing: border-box;
  // overflow: hidden;

  // .header-title {
  //   position: relative;
  //   padding-left: 22px;
  // }

  // .header-title p {
  //   margin: 0;
  //   font-size: 20px;
  //   font-weight: 600;
  //   color: #fff;
  //   position: relative;
  //   z-index: 2;
  // }

  // .header-title::before {
  //   content: "";
  //   position: absolute;
  //   top: 1;
  //   left: 0;
  //   width: 105%;
  //   // margin-bottom: 79px;
  //   height: 125%;
  //   background: linear-gradient(90deg, #ffdd78, #f17951, #ffdd78);
  //   background-size: 800%;
  //   animation: moveBackground 4s infinite linear;
  //   z-index: 1;
  //   filter: blur(10px);
  // }

  // @keyframes moveBackground {
  //   0% {
  //     background-position: 10%;
  //   }

  //   100% {
  //     background-position: 100%;
  //   }
  // }

  // .header-side-links {
  //   font-weight: 600;
  //   font-size: 17px;
  //   color: rgb(7, 7, 7);
  //   margin-right: 16px;
  // }

  // .header-side-links ul {
  //   display: flex;
  //   list-style: none;
  //   gap: 12px;
  //   padding: 0;
  //   margin: 0;
  // }

  // .header-side-links li {
  //   cursor: pointer;
  //   text-decoration: none;
  //   color: rgb(7, 7, 7);
  //   transition: color 0.3s;
  // }

  // .header-side-links li:hover {
  //   color: rgba(255, 255, 255, 0.826);
  // }

  // .btn {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   cursor: pointer;
  //   background-color: #ffdd78;
  //   width: 140px;
  //   height: 40px;
  //   border-radius: 30px;
  //   font-size: 16px;
  //   font-weight: bold;
  //   border: none;

  //   &:hover {
  //     background-color: #f15015;
  //   }
  // }

  // .link2 a {
  //   color: black;
  //   text-decoration: none;
  //   transition: color 0.3s ease;
  // }

  // .link2 a:hover {
  //   color: white;
  // }

  @media (max-width: 768px) {
    .header {
      padding: 0 2px;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
    }

    .header-title {
      margin-bottom: 8px;
    }

    .header-title p {
      font-size: 12px;
    }

    .header-side-links {
      font-size: 12px;
      margin-right: 0;
      margin-bottom: 8px;
    }

    .header-side-links ul {
      gap: 8px;
      flex-wrap: wrap;
      justify-content: center;
    }

    .header-title::before {
      width: 80%;
    }

    .btn-donate {
      width: 100px;
      height: 32px;
      font-size: 12px;
    }

    .header-side-links {
      display: none;
    }
  }
}

// .home .image {
//   width: 100%;
//   height: auto;
//   // margin-top: 70px;


//   /* Adjust image size on smaller screens */
//   @media (max-width: 767px) {
//     .home .image {
//       content: url("../src/assests/phoneImg.png");
//       width: 100%;
//       height: auto;
//       margin-top: 70px;
//     }
//   }
// }

.about {
  background-color: #f0dcd5;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;

  .about-image img {
    width: 100%;
    height: auto;
    max-width: 500px;
    border-radius: 15px;
    animation: moveUpDown 3s infinite ease-in-out;
  }

  @keyframes moveUpDown {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 600px;

    .heading-aboutus {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 20px;

      p {
        font-size: 24px;
        font-weight: bold;
        color: #f17951;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    .right-side-down {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        padding: 0 1rem;
      }

      .readmore_btn {
        display: flex;
        align-items: center;
        gap: 4px;

        a {
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          color: #f17951;
        }

        .arrow-button {
          color: #f17951;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 4rem 2rem;
    gap: 3rem;

    .about-image img {
      width: 80%;
    }

    .heading {
      .heading-aboutus p {
        font-size: 28px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 1rem;
    gap: 2rem;

    .about-image img {
      width: 90%;
    }

    .heading {
      .heading-aboutus p {
        font-size: 24px;
      }

      .right-side-down p {
        font-size: 16px;
      }

      .readmore_btn a {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
    gap: 1rem;

    .about-image img {
      width: 100%;
    }

    .heading {
      .heading-aboutus p {
        font-size: 22px;
      }

      .right-side-down p {
        font-size: 14px;
      }

      .readmore_btn a {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 360px) {
    padding: 1.5rem 1rem;

    .heading {
      .heading-aboutus p {
        font-size: 20px;
      }

      .right-side-down p {
        font-size: 13px;
      }

      .readmore_btn a {
        font-size: 13px;
      }
    }
  }
}


.readmore {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f0dcd5;
  padding: 20px;

  .chairman_heading {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    display: flex;
  }

  .chairman_heading p {
    font-size: 38px;
    margin-top: 30px;
    font-weight: 600;
  }

  .about {
    display: flex;
    flex-direction: row;
    gap: 130px;
  }

  .about img {
    width: 550px;
    height: 500px;
    margin-right: 60px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .about p {
    font-size: 18px;
    font-weight: 400;
    color: rgb(67, 66, 66);
    margin-left: 44px;
  }

  .abouthead p {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content {
    margin-left: 40px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .content p {
    font-size: 20px;
    color: rgb(67, 66, 66);
    font-weight: 500;
  }

  .childrenConatiner {
    margin: 30px;
  }

  .childrenConatiner h4 {
    margin-top: 20px;
  }

  .vision {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
  }

  .vision p {
    font-weight: 600;
    font-size: 32px;
  }

  .childrenConatiner p {
    font-size: 18px;
  }

  .visionTxt {
    margin-right: 40px;
    margin-left: 20px;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .about {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .about img {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 20px;
    }

    .about p {
      font-size: 16px;
      margin-left: 0;
      text-align: center;
    }

    .content {
      margin-left: 20px;
      margin-right: 20px;
    }

    .content p {
      font-size: 18px;
    }

    .childrenConatiner {
      margin: 20px;
    }

    .childrenConatiner h4 {
      font-size: 20px;
    }

    .childrenConatiner p {
      font-size: 16px;
    }

    .vision p {
      font-size: 28px;
    }

    .visionTxt {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  @media (max-width: 480px) {
    .chairman_heading p {
      font-size: 30px;
      margin-top: 20px;
    }

    .about {
      flex-direction: column-reverse;
      align-items: center;
      gap: 10px;
    }

    .about img {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 20px;
    }

    .about p {
      font-size: 14px;
      margin-left: 0;
      text-align: start;
    }

    .abouthead p {
      font-size: 28px;
    }

    .content {
      margin-left: 10px;
      margin-right: 10px;
    }

    .content p {
      font-size: 16px;
      text-align: center;
    }

    .childrenConatiner {
      margin: 10px;
    }

    .childrenConatiner h4 {
      font-size: 18px;
    }

    .childrenConatiner p {
      font-size: 14px;
    }

    .vision p {
      font-size: 24px;
    }

    .visionTxt {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.gallery {
  position: relative;
  transition: filter 0.3s ease;
  z-index: 1;
}

.gallery.blurred {
  filter: blur(5px);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.card-image img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}


.zoomed-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.zoomed-image {
  max-width: 100%;
  max-height: 100%;
  transform: scale(1.5);
  transition: transform 0.3s ease;
}

.zoomed-image-overlay img {
  filter: none;
  transition: transform 0.3s ease-in-out;
}

.gallery {
  flex-direction: column;
  background-color: #f1d3c8;
  display: flex;
  padding: 20px;
  padding-bottom: 80px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .heading p {
    font-size: 30px;
    font-weight: bold;

    text-align: center;
    padding: 20px;
    color: #f17951;
  }

  .heading img {
    width: 30px;
    height: 30px;
  }

  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .card {
    position: relative;
    width: 300px;
    height: auto;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s;
    cursor: pointer;

    img {
      width: 100%;
      height: 200px;
      transition: transform 0.3s ease-in-out;
    }

    img:hover {
      transform: scale(1.2);
    }
  }
}


.contact {
  background-image: url("../src/assests/kaashi.jpg");
  background-size: cover;
  opacity: 0.7;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 90px;
  box-sizing: border-box;
  margin: 0 auto;
}

.address {
  padding-left: 40px;
  width: 30%;
}

.address p {
  font-size: 15px;
  color: whitesmoke;
  padding: 3px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.address p svg {
  margin-right: 8px;
}

.BankDetails {
  width: 30%;
  padding-left: 40px;
}

.icons {
  margin-bottom: 11px;
}

.BankDetails p {
  font-size: 15px;
  color: whitesmoke;
  padding: 3px;
  margin: 5px 0;
}

.BankDetails .address p {
  padding-top: 0;
  padding-left: 0;
  text-align: left;
}

.termsandcondition {
  width: 30%;
  padding: 20px;
  margin-bottom: 12px;
}

.termsandcondition p a {
  color: white;
  font-size: 18px;
  text-decoration: none;
}

// .QR img {
//   width: 200px;
//   height: 200px;
//   margin-right: 120px;
// }

// .QR p {
//   font-size: 30px;
//   color: white;
//   font-weight: 600;
//   margin-left: 6px;
// }

@media (max-width: 1400px) {
  .contact {
    height: auto;
    gap: 20px;
  }

  .address,
  .BankDetails,
  .termsandcondition {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .address,
  .BankDetails {
    padding: 0;
  }

  .address p,
  .BankDetails p,
  .termsandcondition p {
    font-size: 14px;
  }

  .termsandcondition p a {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .contact {
    flex-direction: column;
    align-items: center ;
    justify-content: center ;
    padding: 10px;
  }

  .address p,
  .BankDetails p,
  .termsandcondition p {
    font-size: 12px;
  }

  .termsandcondition p a {
    font-size: 14px;
  }
}

.ourWork {
  background-color: #f0dcd5;
  height: 80vh;
  padding: 0 5rem;
  padding-top: 2rem;
  box-sizing: border-box;

  .heading {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 10px;
    text-align: center;
  }

  .heading p {
    font-size: 34px;
    font-weight: bold;
    color: #f17951;
    padding: 20px;
  }

  .heading img {
    width: 30px;
    height: 30px;
    bottom: 10px;
  }

  .slick-slide {
    padding: 0 10px;
  }

  .card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    margin: 0 10px;
    box-sizing: border-box;
  }

  .card .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  .card .image img {
    width: 250px;
    height: 250px;
    max-width: 100%;
    height: 200px;
    border-radius: 20px;
  }

  .sub-text {
    color: #f17951;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  @media (max-width: 1024px) {
    .ourWork {
      padding: 0 2rem;
    }

    .card .image img {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 600px) {
    .ourWork {
      padding: 0 1rem;
    }

    .card .image img {
      width: 150px;
      height: 150px;
    }

    .heading p {
      font-size: 28px;
    }

    .sub-text {
      font-size: 18px;
    }
  }

  @media (max-width: 480px) {
    .ourWork {
      padding: 0.5rem;
    }

    .card .image img {
      width: 120px;
      height: 120px;
    }

    .heading p {
      font-size: 24px;
    }

    .sub-text {
      font-size: 16px;
    }
  }
}



// .testimonial {
//   background-color: #f1d3c8;
//   padding-top: 2rem;
//   height: 70vh;

//   .heading {
//     justify-content: center;
//     align-items: center;
//     display: flex;
//   }

//   .heading p {
//     font-size: 34px;
//     font-weight: bold;
//     color: #f17951;
//     padding: 20px;
//   }

//   .heading img {
//     width: 30px;
//     height: 30px;
//     bottom: 10px;
//   } 
 
// }


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0dcd5;
}

.DonateNow {
  max-width: 800px;
  margin: auto;
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 20px;
  background-color: #f17951;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;



  h1 {
    text-align: center;
    color: #fbfffb;
    margin-bottom: 20px;
  }

  .shloka {
    text-align: center;
    font-size: 20px;
    color: #fffafa;
    margin-bottom: 20px;
    font-weight: 900;
  }

  .bank-details {
    background-color: #e8f5e9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .donation-form label {
    display: block;
    margin: 10px 0 5px;
  }

  .donation-form input,
  .donation-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .donation-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .donation-form button:hover {
    background-color: #45a049;
  }

  .payText {
    font-size: 18px;
    color: white;
    font-weight: bolder;
  }

  .qr-code {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .qr-code img {
    max-width: 250px;
    height: auto;
  }


  @media screen and (max-width: 600px) {
    .container {
      padding: 15px;
    }

    h1 {
      font-size: 24px;
    }

    .shloka {
      font-size: 16px;
    }

    .qr-code img {
      max-width: 100px;
    }
  }
}

.innerDonatediv {
  margin-top: 80px;
}

.privacy {
  background-image: url("./assests/cow-group.jpg");
  width: 100%;
  height: 130vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .heading {
    margin-bottom: 32px;
  }

  .heading p {
    color: #f17951;
    font-size: 36px;
    font-weight: bold;
  }

  .content {
    background-color: white;
    width: 80%;
    max-height: 90vh;
    overflow: auto;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
}

.refund {
  background-image: url("./assests/cow-group.jpg");
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .heading {
    margin-bottom: 32px;
  }

  .heading p {
    color: #f17951;
    font-size: 36px;
    font-weight: bold;
  }

  .content {
    background-color: white;
    width: 80%;
    max-height: 90vh;
    overflow: auto;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
}



.terms {
  background-image: url("./assests/cow-group.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .heading {
    margin-top: 50px;
    margin-bottom: 32px;
  }

  .heading p {
    color: #f17951;
    font-size: 36px;
    font-weight: bold;
  }

  .content {
    background-color: white;
    width: 80%;
    max-height: 90vh;
    overflow: auto;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }

  content h3 {
    margin: 10px;
  }

  content p {
    margin-bottom: 10px;
  }
}

.disclaimer {
  background-image: url("./assests/cow-group.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .heading {
    margin-top: 50px;
    margin-bottom: 32px;
  }

  .heading p {
    color: #f17951;
    font-size: 36px;
    font-weight: bold;
  }

  .content {
    background-color: white;
    width: 80%;
    max-height: 90vh;
    overflow: auto;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
}


.reset-password-container {
  background-color: #333;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.reset-password-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: 1px solid #444;
  border-radius: 8px;
  background-color: #2c2c2c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.reset-password-box h2 {
  margin-bottom: 20px;
  color: #fff;
}

.input-group {
  width: 100%;
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: red;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid red;
  background-color: transparent;
  color: #fff;
  box-sizing: border-box;
}

.input-group input::placeholder {
  color: #ffdddd;
}

.reset-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
}