@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide the Google Translate toolbar */
body > .skiptranslate {
    display: none !important;
  }
  
  /* Hide the Google Translate link */
  a.VIpgJd-ZVi9od-l4eHX-hSRGPd {
    display: none !important;
  }
  
  body {
    top: 0px !important; /* Adjust page layout */
  }
  
  /* for marquee tag */
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Default speed */
.animate-marquee {
  animation: marquee 10s linear infinite; /* Adjust the duration for speed */
}

 /* Slower speed for mobile */
@media (max-width: 640px) { /* Tailwind's default 'sm' breakpoint */
  .animate-marquee {
    animation-duration: 20s; /* Increase the duration for mobile */
  }
}

/* Medium speed for tablets */
@media (min-width: 640px) and (max-width: 824px) { /* Tailwind's default 'md' breakpoint */
  .animate-marquee {
    animation-duration: 12s; /* Adjust for tablet speed */
  }
}